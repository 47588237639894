<template>
  <layout-app>
    <router-view />
  </layout-app>
</template>

<script>
import LayoutApp from '@core/layouts/layout-app/LayoutApp.vue'

export default {
  components: {
    LayoutApp,
  },
}
</script>
